<template>
  <div>
    <h1 class="mb-0">Iniciar Sesión</h1>
    <p>Ingresa tu RUT y contraseña para acceder</p>
    <sign-in1-form></sign-in1-form>
  </div>
</template>

<script>
import SignIn1Form from './Forms/SignIn1Form'

export default {
  name: 'SignIn1',
  components: { SignIn1Form },
  data: () => ({})
}
</script>
