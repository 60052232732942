<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(login)">
      <ValidationProvider
        vid="rut"
        name="RUT"
        rules="required|min:9|max:10"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="rut">RUT</label>
          <input
            type="text"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="rut"
            name="rut"
            v-model="form.rut"
            v-mask="['########-X', '#######-X']"
            placeholder="Ingrese su RUT"
            required
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="password"
        name="contraseña"
        rules="required|min:6"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="passwordInput">Contraseña</label>
          <input
            type="password"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="password"
            name="password"
            v-model="form.password"
            placeholder="Ingrese su Contraseña"
            required
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <router-link to="/auth/password-reset" class="float-right">
        Olvidé mi contraseña
      </router-link>
      <div class="d-inline-block w-100">
        <hr />
        <button type="submit" class="btn btn-primary btn-block float-right">
          Iniciar Sesión
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import csrf from '@/apis/Csrf'
import axios from 'axios'
import { core } from '@/config/pluginInit'

export default {
  name: 'FormLogin',
  created () {
    this.$store.commit('stop')
  },
  data: () => ({
    form: {
      rut: '',
      password: ''
    },
    errors: [],
    url: process.env.VUE_APP_URL
  }),
  methods: {
    async login () {
      try {
        this.$store.commit('run')
        await csrf.getCookie()
        const login = await axios.post('/api/login', this.form)
        this.$store.commit('auth/SET_USER', login.data)
        this.$store.dispatch('auth/fetchUser')
        this.$router.push((this.$route.query.hasOwnProperty('purchase_request')) ?  location.replace(`${this.url}${this.$route.query.purchase_request}`) : '/')
      } catch (error) {
        console.log(error)
        // const errors = error.response.data.errors
        // for (const key in errors) {
          //   core.showSnackbar('error', errors[key][0])
        //   return false
        // }
      }finally{
        this.$store.commit('stop')
      }
    }
  }
}
</script>
